<!-- Start header -->
<div id="top-of-page"></div>
<lib-nav
  (openSidebar)="openSidebar()"
  [notify]="notify"
  (closeNotify)="closeNotify()"
>
</lib-nav>
<!-- End header -->

<div class="px-4 pt-4 sm:px-6 md:pt-0" #titleRef>
  <ng-container *ngIf="isShow">
    <!-- title -->
    <div class="flex flex-col px-4 text-center sm:px-4">
      <h1
        class="text-2xl font-semibold uppercase tracking-wider text-gray-700 sm:text-3xl"
        i18n
      >
        Manual
      </h1>
      <div class="m-1 mx-auto w-10 border border-blue-500"></div>
    </div>

    <!-- important notice / general operations-->
    <div class="flex flex-col items-center px-1 py-1 sm:px-12">
      <!-- important notice -->
      <div class="sm:max-w-4xl">
        <h1
          class="text-center text-xl font-medium uppercase tracking-wide text-red-500 md:py-2 lg:py-3"
          i18n
        >
          Important notice
        </h1>
        <p class="px-1 py-0.5 text-left text-sm text-gray-500" i18n>
          Dealer's manuals (DM) are intended primarily for use by professional
          bicycle mechanics.
        </p>

        <p class="px-1 py-0.5 text-left text-sm text-gray-500" i18n>
          The following is an explanation of the terms displayed on the website.
        </p>

        <div
          class="grid grid-flow-row grid-cols-2 gap-0.5 bg-gray-50 p-2 text-sm shadow-sm sm:grid-cols-2 md:my-1 md:grid-cols-3 md:gap-1"
          *ngIf="isExpanded"
        >
          <div class="flex items-center">
            <i
              class="inline-block w-10 flex-shrink-0 text-center text-white shadow-md"
              style="background-color: #bd362f"
              >DM</i
            >
            <span class="break-words px-1 leading-4 sm:pl-4 md:w-44" i18n
              >Dealer's Manual</span
            >
          </div>
          <div class="flex items-center">
            <i
              class="inline-block w-10 flex-shrink-0 text-center text-white shadow-md"
              style="background-color: #51a351"
              >UM</i
            >
            <span class="break-words px-1 leading-4 sm:pl-4 md:w-44" i18n
              >User's Manual</span
            >
          </div>
          <div class="flex items-center">
            <i
              class="inline-block w-10 flex-shrink-0 text-center text-white shadow-md"
              style="background-color: #005686"
              >SI</i
            ><span class="break-words px-1 leading-4 sm:pl-4 md:w-44" i18n
              >Service Instructions</span
            >
          </div>
          <div class="flex items-center">
            <i
              class="inline-block w-10 flex-shrink-0 text-center text-white shadow-md"
              style="background-color: #fd8c34"
              >EV</i
            ><span class="break-words px-1 leading-4 sm:pl-4 md:w-44" i18n
              >Exploded View</span
            >
          </div>
          <div class="flex items-center">
            <i
              class="inline-block w-10 flex-shrink-0 text-center text-white shadow-md"
              style="background-color: #4aacc5"
              >QM</i
            >
            <span class="break-words px-1 leading-4 sm:pl-4 md:w-44" i18n
              >Quick Manual</span
            >
          </div>
          <div class="flex items-center">
            <i
              class="inline-block w-10 flex-shrink-0 text-center text-white shadow-md"
              style="background-color: #e6b8b7"
              >SM</i
            ><span class="break-words px-1 leading-4 sm:pl-4 md:w-44" i18n
              >Service Manual</span
            >
          </div>
        </div>
        <p class="px-1 py-0.5 text-left text-sm text-gray-500" i18n>
          Currently available manuals and technical documents from Shimano are
          posted on this site.
        </p>

        <p class="text-center text-sm">
          <span class="inline-block w-5 align-middle" (click)="toggleDocType()">
            <app-chevron-down-outline-icon
              class="block transition duration-300 ease-in-out"
              [ngClass]="{
                'rotate-180 transform': isExpanded,
                '': !isExpanded
              }"
              svgClass="h-6 w-6"
            ></app-chevron-down-outline-icon>
          </span>
        </p>
      </div>

      <!-- general operations -->
      <div
        #goRef
        class="mx-auto my-0 flex w-full flex-col sm:mx-12 sm:my-2 sm:max-w-lg"
      >
        <div class="flex w-full flex-row">
          <i
            class="inline-block w-10 flex-shrink-0 border text-center text-white"
            style="background-color: #bd362f"
            >DM</i
          >
          <a
            href="/pdfs/dm/GN0001/DM-GN0001.pdf"
            class="sm:text-md flex items-center px-2 text-sm font-medium text-bluemine-700 hover:text-blue-700 md:text-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span i18n>General Operations</span>
            <span class="pl-2">(DM-GN0001)</span>
            <svg
              viewBox="0 0 128 128"
              xml:space="preserve"
              class="ml-2 h-5 w-5"
            >
              <path
                d="M104 126H24c-5.514 0-10-4.486-10-10V12c0-5.514 4.486-10 10-10h40.687a9.932 9.932 0 0 1 7.07 2.929l39.314 39.314a9.932 9.932 0 0 1 2.929 7.07V116c0 5.514-4.486 10-10 10zM24 6c-3.309 0-6 2.691-6 6v104c0 3.309 2.691 6 6 6h80c3.309 0 6-2.691 6-6V51.313a6.044 6.044 0 0 0-1.757-4.242L68.929 7.757A5.96 5.96 0 0 0 64.687 6H24z"
                style="fill: #fd4233"
              />
              <path
                d="M95.21 80.32c-.07-.51-.48-1.15-.92-1.58-1.26-1.24-4.03-1.89-8.25-1.95-2.86-.03-6.3.22-9.92.73-1.62-.93-3.29-1.95-4.6-3.18-3.53-3.29-6.47-7.86-8.31-12.89.12-.47.22-.88.32-1.3 0 0 1.98-11.28 1.46-15.1-.07-.52-.12-.67-.26-1.08l-.17-.44c-.54-1.25-1.6-2.57-3.26-2.5l-.98-.03h-.02c-1.86 0-3.36.95-3.76 2.36-1.2 4.44.04 11.09 2.29 19.69l-.58 1.4c-1.61 3.94-3.63 7.9-5.41 11.39l-.23.45c-1.88 3.67-3.58 6.79-5.13 9.43l-1.59.84c-.12.06-2.85 1.51-3.49 1.89-5.43 3.25-9.03 6.93-9.63 9.85-.19.94-.05 2.13.92 2.68l1.54.78c.67.33 1.38.5 2.1.5 3.87 0 8.36-4.82 14.55-15.62 7.14-2.32 15.28-4.26 22.41-5.32 5.43 3.05 12.11 5.18 16.33 5.18.75 0 1.4-.07 1.92-.21.81-.22 1.49-.68 1.91-1.3.82-1.23.98-2.93.76-4.67zM36.49 99.33c.7-1.93 3.5-5.75 7.63-9.13.26-.21.9-.81 1.48-1.37-4.32 6.89-7.21 9.63-9.11 10.5zM60.95 43c1.24 0 1.95 3.13 2.01 6.07.06 2.94-.63 5-1.48 6.53-.71-2.26-1.05-5.82-1.05-8.15 0 0-.05-4.45.52-4.45zm-7.3 40.14c.87-1.55 1.77-3.19 2.69-4.92 2.25-4.25 3.67-7.57 4.72-10.3 2.1 3.82 4.72 7.07 7.79 9.67.39.32.8.65 1.22.98-6.25 1.23-11.66 2.74-16.42 4.57zm39.43-.35c-.38.23-1.47.37-2.17.37-2.26 0-5.07-1.03-9-2.72 1.51-.11 2.9-.17 4.14-.17 2.27 0 2.94-.01 5.17.56 2.22.57 2.25 1.72 1.86 1.96z"
                style="fill: #ff402f"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- model input / seach button -->
<div
  *ngIf="isShow"
  class="sticky z-10 flex flex-col items-center bg-white px-2 py-2 sm:px-12"
  [style.top.px]="searchOffset"
>
  <div class="flex flex-row flex-nowrap items-center px-2 py-1 sm:px-12">
    <div class="flex flex-col items-center justify-center sm:w-96">
      <!-- model input -->
      <div
        class="mx-auto flex w-full flex-col pl-1.5 pr-0.5 sm:mx-12 sm:max-w-lg"
        action="result"
      >
        <div class="relative h-11 w-full text-gray-400">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2"
          >
            <app-search-solid-icon size="24"></app-search-solid-icon>
          </div>
          <input
            #filterRef
            id="model_field"
            class="block h-full w-full rounded-sm border border-gray-300 pl-10 pr-3 leading-6 text-gray-900 placeholder-gray-500 focus-within:text-gray-700 focus:border-blue-500 focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
            placeholder="Model No. only"
            type="text"
            name="model"
            autocapitalize="none"
            i18n-placeholder
            [(ngModel)]="inputModel"
            [matAutocomplete]="auto"
            (keyup.enter)="search()"
            (ngModelChange)="inputModelChange($event)"
          />

          <mat-autocomplete #auto="matAutocomplete" panelWidth="auto">
            <mat-option
              *ngFor="let tag of modelTagSets$ | async"
              (onSelectionChange)="addModelTag(tag.key)"
            >
              {{ tag.key }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>

    <!-- search button -->
    <div class="pl-0.5 pr-1.5 text-left sm:mx-auto sm:max-w-lg">
      <button
        type="button"
        class="mx-auto inline-flex h-11 w-28 items-center justify-center bg-blue-500 px-2 py-2 text-center text-sm font-medium leading-4 text-white hover:bg-bluemine-500 focus:outline-none focus:ring-2 focus:ring-bluemine-500 focus:ring-offset-2"
        (click)="search()"
      >
        Search
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="isShow"
  #searchbarRef
  class="flex flex-col items-center bg-white px-6 sm:px-12"
  [ngClass]="{
    'sticky z-10': !isMobile
  }"
  [ngStyle]="{ 'top.px': isMobile ? 0 : searchOffset + 68 }"
>
  <!-- selected tags / tag selector -->
  <div class="flex w-full flex-col px-4 pb-2 md:pb-6 lg:px-12">
    <!-- selected tags -->
    <div class="w-full flex-wrap text-left sm:mx-auto sm:flex sm:max-w-lg">
      <div class="flex w-full flex-row pb-0.5">
        <app-tag-outline-icon size="22"></app-tag-outline-icon>
        <span class="pl-2 text-sm text-gray-500"
          >Tag (Choose from the list below)</span
        >
      </div>
      <div class="ml-5">
        <span
          *ngFor="let tag of activeTags$ | async; last as last"
          class="mx-1 my-1 inline-flex items-center rounded-full border border-gray-400 py-1 pl-3 pr-2 text-sm font-normal uppercase tracking-wider"
          [ngClass]="{ 'animate-scale-down-center': last && tagChangeState }"
        >
          {{ tag.name }}
          <button
            (click)="removeTag(tag)"
            type="button"
            class="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-bluemine-400 hover:bg-bluemine-200 hover:text-bluemine-500 focus:bg-bluemine-500 focus:text-white focus:outline-none"
          >
            <span class="sr-only">Remove large option</span>
            <app-x-outline-icon svgClass="h-3 w-3"></app-x-outline-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- tag selector -->
<div *ngIf="isShow" class="flex flex-col items-center px-4 pb-6">
  <div class="w-full max-w-md border sm:max-w-3xl lg:w-5/6 lg:max-w-6xl">
    <div class="py-4">
      <div class="border-b border-gray-200">
        <div class="flex overflow-x-auto overflow-y-hidden">
          <div class="ml-3 sm:ml-10">
            <nav class="-mb-px flex space-x-1">
              <!-- Current: "border-bluemine-500 text-bluemine-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
              <ng-container *ngFor="let tab of tabs; let i = index">
                <a
                  (click)="selectTabIndex(i)"
                  href=""
                  class="whitespace-nowrap rounded-t-sm border-b-4 px-4 py-3 pb-2 text-sm font-normal uppercase tracking-wider"
                  [ngClass]="{
                    'border-bluemine-500 bg-bluemine-500 text-white':
                      i === selectedCategory,
                    'max-w-2/8 overflow-hidden overflow-ellipsis border-transparent text-gray-500 hover:border-blue-500 hover:text-bluemine-700':
                      i !== selectedCategory
                  }"
                  aria-current="page"
                >
                  {{ tagLabels[tab] }}
                </a>
              </ng-container>
            </nav>
          </div>
        </div>
      </div>

      <span class="relative z-0 my-1 hidden divide-x md:inline-flex">
        <span class="inline-flex items-center px-1 text-gray-500">
          <app-filter-outline-icon size="16"></app-filter-outline-icon>
        </span>

        <button
          *ngFor="let bikeType of bikeTypes; let i = index"
          (click)="onClick(i)"
          type="button"
          class="relative inline-flex items-center border-gray-300 px-4 text-sm font-normal uppercase tracking-wider transition duration-300 ease-in-out focus:outline-none focus:ring-0"
          [ngClass]="{
            'bg-bluemine-500 bg-opacity-100 text-white':
              i === selectBikeTypeIndex,
            'bg-white bg-opacity-0 text-gray-500 hover:bg-gray-50':
              i !== selectBikeTypeIndex
          }"
        >
          {{ bikeType.name }}
        </button>
      </span>

      <span class="my-1 inline-flex md:hidden">
        <span class="inline-flex items-center px-1 text-gray-500">
          <app-filter-outline-icon size="16"></app-filter-outline-icon>
        </span>
        <label for="tabs" class="sr-only">Select a tab</label>
        <select
          #select
          id="tabs"
          name="tabs"
          class="block w-full border-gray-300 py-2 pl-3 pr-10 text-base focus:border-transparent focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
          (change)="onClick(select.value)"
        >
          <option
            [selected]="i === selectBikeTypeIndex"
            [value]="i"
            *ngFor="let bikeType of bikeTypes; let i = index"
          >
            {{ bikeType.name }}
          </option>
        </select>
      </span>

      <div class="flex flex-row justify-center p-5">
        <ng-container *ngFor="let tagSet of tagSets$ | async; let i = index">
          <app-docs-collection
            *ngIf="tagSet.key === selectedCategoryName"
            [tags]="tagSet.buckets"
            [category]="tagSet.key"
          ></app-docs-collection>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Back to top button -->
<button
  type="button"
  [ngStyle]="{ display: windowScrolled ? 'block' : 'none' }"
  class="fixed bottom-5 right-5 z-30 inline-block hidden rounded-full border bg-blue-700 p-3 text-xs font-medium uppercase leading-tight text-white shadow-sm transition duration-150 ease-in-out hover:bg-white hover:text-bluemine-300 hover:shadow-sm focus:bg-blue-700 focus:text-white focus:shadow-sm focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-sm"
  id="btn-back-to-top"
  (click)="onClickBackToTop()"
>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    class="h-4 w-4"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
    ></path>
  </svg>
</button>

<lib-footer></lib-footer>
