<!-- Start Nav -->
<div id="top-of-page"></div>
<lib-nav
  [sidebar]="true"
  [toolbar]="true"
  (openSidebar)="openSidebar()"
  [notify]="notify"
  (closeNotify)="closeNotify()"
>
  <span tools-desktop>
    <div class="flex flex-1 justify-between px-1 md:px-5">
      <app-toolbar-button (emitClick)="toggleBookmark()">
        <app-star-solid-icon
          size="20"
          [svgClass]="isBookmarked ? 'text-blue-900' : 'text-gray-400'"
        ></app-star-solid-icon>
        <span class="sr-only">Bookmark</span>
      </app-toolbar-button>

      <a
        href="/pdfs/ev/{{ this.docId }}/{{
          (explodedView$ | async)?.pdf_file_path
        }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        <app-toolbar-button>
          <app-adobe-pdf-outline-icon size="20"></app-adobe-pdf-outline-icon>
          <span class="sr-only">PDF</span>
        </app-toolbar-button>
      </a>
    </div>
  </span>
  <span tools-smartphone>
    <nav aria-label="tools">
      <div class="space-y-1 p-4">
        <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
        <a
          (click)="toggleBookmark(); (false)"
          href="#"
          class="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
        >
          <app-star-solid-icon
            [svgClass]="
              isBookmarked
                ? 'text-blue-500 group-hover:text-gray-500 mr-4 h-6 w-6'
                : 'text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6'
            "
          ></app-star-solid-icon>
          <span i18n>Bookmark</span>
        </a>

        <a
          class="group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
          href="/pdfs/ev/{{ this.docId }}/{{
            (explodedView$ | async)?.pdf_file_path
          }}"
          target="_blank"
          rel="noopener noreferrer"
        >
          <app-adobe-pdf-outline-icon
            svgClass="text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"
          ></app-adobe-pdf-outline-icon>
          <span i18n>PDF</span>
        </a>
      </div>
    </nav>
  </span>
</lib-nav>
<!-- End Nav -->

<ng-container *ngIf="explodedView$ | async as explodedView">
  <!-- Start left sidebar for mobile -->
  <app-left-sidenav [expanded]="isSidebarOpen" (close)="closeSidebar()">
    <div class="flex h-8 flex-shrink-0 items-center px-4">
      <div class="flex w-full border md:ml-0">
        <label for="search_field" class="sr-only">Search</label>
        <div class="relative w-full text-gray-400 focus-within:text-gray-700">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2"
          >
            <app-search-solid-icon size="16"></app-search-solid-icon>
          </div>
          <input
            #filterRef1
            id="search_field"
            class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
            placeholder="Search"
            type="search"
            name="filter"
            (search)="onClickFilter($event)"
          />
        </div>
      </div>
    </div>
    <nav
      #explodedViewBomMobileContainer
      class="mt-3 h-0 flex-1 overflow-y-auto"
    >
      <!--BOM List 区切り-->
      <ul class="flex flex-grow flex-col p-1">
        <app-bom-item *ngFor="let bom of boms$ | async" [bom]="bom">
        </app-bom-item>
      </ul>
      <div class="mx-1 my-3 border border-red-900 p-2">
        <p class="text-xs text-red-700">A Same parts.</p>
        <p class="text-xs text-red-700">
          B Parts are usable, but differ in materials, appearance, finish, size,
          etc.Absence of mark indicates non-interchangeability.
        </p>
      </div>
    </nav>
  </app-left-sidenav>
  <!-- End left sidebar for mobile -->

  <!-- Start Contents -->
  <main class="flex justify-center">
    <div class="relative flex w-full max-w-screen-xl flex-col">
      <div class="flex justify-center">
        <div class="relative mb-96">
          <img
            #explodedViewImageContainer
            (load)="onImageLoad()"
            class="select-none object-cover"
            src="/images/ev/{{ docId }}/{{ explodedView.image_file_path }}"
            alt=""
          />
          <svg
            [attr.viewBox]="viewBoxValue"
            fill="none"
            class="absolute left-0 top-0 max-h-full max-w-full"
          >
            <g
              app-hotspot-item
              *ngFor="let hotSpot of explodedView.hotspots; index as i"
              [hotSpot]="hotSpot"
              [imageSize]="imageSize"
            ></g>
          </svg>
          <!-- </div> -->
        </div>

        <!-- Start nav  -->
        <nav
          class="sticky top-36 hidden max-h-3/4 w-56 flex-col md:flex md:flex-shrink-0"
        >
          <div class="m-2">
            <label for="search_field" class="sr-only">Search</label>
            <div
              class="relative grid w-full justify-items-end text-gray-400 focus-within:text-gray-700"
            >
              <div
                class="pointer-events-none absolute inset-y-0 flex w-full items-center px-2"
              >
                <app-search-solid-icon
                  svgClass="h-6 w-6"
                ></app-search-solid-icon>
              </div>
              <input
                #filterRef2
                id="filter_field"
                class="h-full w-full border border-gray-300 py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 opacity-70 transition duration-500 ease-in-out focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-bluemine-500 sm:text-sm"
                placeholder="Search"
                type="search"
                name="filter"
                (search)="onClickFilter($event)"
              />
            </div>
          </div>
          <ul
            #explodedViewBomDesktopContainer
            class="flex h-screen max-w-full flex-grow flex-col overflow-y-auto overflow-x-hidden p-1"
          >
            <!--BOM List 区切り-->
            <app-bom-item *ngFor="let bom of boms$ | async" [bom]="bom">
            </app-bom-item>
          </ul>
          <div class="mx-1 my-3 border border-red-900 p-2">
            <p class="text-xs text-red-700">A Same parts.</p>
            <p class="text-xs text-red-700" i18n>
              B Parts are usable, but differ in materials, appearance, finish,
              size, etc.Absence of mark indicates non-interchangeability.
            </p>
          </div>
        </nav>
        <!-- End nav  -->
      </div>
    </div>
  </main>
  <!-- End Contents -->
</ng-container>

<!-- Start footer -->
<lib-footer></lib-footer>
<!-- End footer -->
