import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
const { pairwise, tap, scan, map, startWidth } = require('rxjs/operators');

import {
  ResultItemService,
  RequestTag,
  Tag,
  BikeType,
  RequestTags,
} from '../search/result-item.service';
import { SearchComponent } from '../search/search.component';

import { Notify, NotifyService } from '../shared/notify.service';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss'],
})
export class DocsComponent implements AfterViewInit, OnDestroy, OnInit {
  /**
   * モバイル 検索バーオフセット
   */
  public SEARCHBAR_OFFSET_NARROW = 49;

  /**
   * デスクトップ 検索バーオフセット
   */
  public SEARCHBAR_OFFSET_WIDE = 117;

  /**
   * 通知バーの高さ
   */
  public NOTIFYBAR_HEIGHT = 48;

  /**
   * タグのラベル名
   */
  public tagLabels: { [key: string]: string } = {
    ['series']: $localize`series`,
    ['component']: $localize`component`,
    ['generation']: $localize`generation`,
  };

  /**
   * 表示
   */
  public isShow = false;

  /**
   * 判例が開いているか状態であるか
   */
  public isExpanded = true;

  /**
   * サイドバーが開いている状態であるか
   */
  public isSidebarOpen = false;

  /**
   * モバイル幅の判定
   */
  public isMobile = false;

  /**
   * 選択中のタグ分類
   */
  public selectedCategory = 0;

  /**
   * 選択中のタグ表示
   */
  public selectedCategoryName = 'series';

  /**
   * 選択中のバイクタイプ
   */
  public selectBikeTypeIndex = 0;

  /**
   * 通知オブジェクト
   */
  public notify: Notify;

  /**
   * ウィンドウのスクロールの状態
   */
  public windowScrolled = false;

  /**
   * 検索バー スティッキー オフセット
   */
  public searchOffset = 0;

  /**
   * スクロールトップ オフセット
   */
  public scrollTopOffset = 0;

  /**
   * タグの変更状態 (0: ダウン, 1: アップ)
   */
  public tagChangeState = false;

  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('filterRef') filterRef!: ElementRef;

  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('titleRef') titleRef!: ElementRef;

  /**
   * サーチバー(デスクトップ)に対する参照 (ViewChild)
   */
  @ViewChild('searchbarRef') searchbarRef!: ElementRef;

  /**
   * フィルタ要素に対する参照 (ViewChild)
   */
  @ViewChild('goRef') goRef!: ElementRef;

  /**
   * インターセクションオブザーバ
   */
  private observer!: IntersectionObserver;

  /**
   * インプットモデル
   */
  public inputModel = '';

  /**
   * タグセット・オブザーバブル
   */
  public tagSets$: Observable<any>;

  /**
   * 選択タグ・オブザーバブル
   */
  public activeTags$: Observable<any>;

  /**
   * モデルサジェスト・オブザーバブル
   */
  public modelTagSets$?: Observable<Tag[]>;

  public tabs = ['series', 'generation', 'component'];

  /**
   * 自転車の種類
   */
  public bikeTypes: BikeType[] = [
    {
      id: 'ROAD',
      name: $localize`ROAD`,
    },
    {
      id: 'GRAVEL',
      name: $localize`GRAVEL`,
    },
    {
      id: 'MTB',
      name: $localize`MTB`,
    },
    {
      id: 'E-BIKE',
      name: $localize`E-BIKE`,
    },
    {
      id: 'LIFESTYLE',
      name: $localize`LIFESTYLE`,
    },
    {
      id: 'URBAN',
      name: $localize`URBAN`,
    },
    {
      id: 'TREKKING',
      name: $localize`TREKKING`,
    },
    {
      id: 'BMX',
      name: $localize`BMX`,
    },
    {
      id: 'OTHERS',
      name: $localize`OTHERS`,
    },
  ];

  /**
   * コンストラクタ
   * @param router Router
   * @param ResultItemService resultItemService
   */
  constructor(
    private title: Title,
    private router: Router,
    private resultItemService: ResultItemService,
    private notifyService: NotifyService
  ) {
    this.notify = this.notifyService.getNotify();
    this.notify.show = true;

    this.title.setTitle(`Manuals | Manuals & Technical Documents`);

    this.tagSets$ = this.resultItemService.tagSetsChanges;
    this.activeTags$ = this.resultItemService.requestTagsChanges;
    this.modelTagSets$ = this.resultItemService.modelTagSetsChanges;

    this.activeTags$.pipe(pairwise()).subscribe((pairedSet: any) => {
      if (pairedSet[0].length < pairedSet[1].length) {
        this.tagChangeState = true;
      } else if (pairedSet[0].length < pairedSet[1].length) {
        this.tagChangeState = false;
      }
    });
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    this.resultItemService.setRange(0, 10);
    this.resultItemService.clearFacets();
    this.resultItemService.search(true);

    this.setViewportScrollerOffset();
  }

  /**
   * AfgerViewInit
   */
  ngAfterViewInit(): void {
    this.isShow = true;
    const margin = this.searchOffset + 10;
    const options = {
      rootMargin: `-${margin}px 0px 0px 0px`,
      threshold: 0,
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      this.handleIntersection(entries);
    }, options);

    this.observer.observe(this.titleRef.nativeElement);

    this.setViewportScrollerOffset();
  }

  /**
   * Destroy
   */
  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private handleIntersection = (entries: IntersectionObserverEntry[]) => {
    let isIntersecting = (entry: IntersectionObserverEntry) => {
      return entry.isIntersecting;
    };

    for (var entry of entries) {
      if (isIntersecting(entry)) {
        this.searchbarRef.nativeElement.classList.remove('search-bar-desktop');
      } else {
        if (!this.isMobile) {
          this.searchbarRef.nativeElement.classList.add('search-bar-desktop');
        }
      }
    }
  };

  /**
   * ウィンドウのリサイズで検索UIのオフセットを変更する
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setViewportScrollerOffset();
  }

  /**
   * (HostListner) ウィンドウスクロール
   * @param $event any
   */
  @HostListener('window:scroll', ['$event'])
  onScroll($event: any): void {
    if (
      document.body.scrollTop > this.scrollTopOffset ||
      document.documentElement.scrollTop > this.scrollTopOffset
    ) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  /**
   * 検索UI の オフセット を変更する
   */
  private setViewportScrollerOffset(): void {
    const viewportWidth = this.width;

    if (viewportWidth <= 760) {
      this.isMobile = true;
      if (!this.notify.show) {
        this.searchOffset = this.SEARCHBAR_OFFSET_NARROW;
        this.scrollTopOffset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset;
      } else {
        this.searchOffset =
          this.SEARCHBAR_OFFSET_NARROW + this.NOTIFYBAR_HEIGHT;
        this.scrollTopOffset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset;
      }
    } else {
      this.isMobile = false;
      if (!this.notify.show) {
        this.searchOffset = this.SEARCHBAR_OFFSET_WIDE;
        this.scrollTopOffset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset;
      } else {
        this.searchOffset = this.SEARCHBAR_OFFSET_WIDE + this.NOTIFYBAR_HEIGHT;
        this.scrollTopOffset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset;
      }
    }
  }

  /**
   * スマートフォン用サイドバーを開く
   */
  public openSidebar(): void {
    this.isSidebarOpen = true;
  }

  /**
   * スマートフォン用サイドバーを閉じる
   */
  public closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  /**
   * ドキュメントの種類の表示・非表示を切り替える
   */
  public toggleDocType(): void {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * タグの種類をクリックし、タグ表示を切り替える
   * @param i 選択されたタグの種類
   * @returns boolean false
   */
  public selectTabIndex(i: number): boolean {
    this.selectedCategory = i;

    switch (i) {
      case 0:
        this.selectedCategoryName = 'series';
        break;
      case 1:
        this.selectedCategoryName = 'generation';
        break;
      case 2:
        this.selectedCategoryName = 'component';
        break;
    }

    return false;
  }

  /**
   * クリックで選択タグからタグを除去する
   * @param tag Tag・インターフェース
   * @returns void
   */
  public removeTag(tag: RequestTag): void {
    this.resultItemService.removeTag(tag);
    this.resultItemService.setRange(0, 10);
    this.resultItemService.search(true);
    this.resultItemService.clearSuggest();
  }

  /**
   * サジェストからモデル・タグを追加する
   * @param value any
   */
  public addModelTag(value: string): void {
    this.resultItemService.addTag({ name: value, category: 'model' });
    this.resultItemService.setRange(0, 10);
    this.resultItemService.search(true);
    this.resultItemService.clearSuggest();
    this.inputModel = '';

    const params = this.resultItemService.getParams();

    this.resultItemService.setRange(0, 10);

    const queryParams: any = {};

    if (this.filterRef.nativeElement.value) {
      queryParams.input_model = this.filterRef.nativeElement.value;
    }

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams[category] = reqTags.names.join(',');
      }
    });

    if (params.docTypes.length) {
      queryParams.doctype = params.docTypes.join(',');
    }

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }

  /**
   * インプットモデルの変更
   * @param event any
   */
  public inputModelChange(event: any): void {
    this.resultItemService.setModel(this.inputModel);
    if (this.inputModel.length > 1) {
      this.resultItemService.setRange(0, 10);
      this.resultItemService.suggest();
    } else {
      this.resultItemService.clearSuggest();
    }
  }

  /**
   * 検索ボタンの押下で検索を開始する
   */
  public search(): void {
    const params = this.resultItemService.getParams();

    this.resultItemService.setRange(0, 10);

    const queryParams: any = {};

    if (this.filterRef.nativeElement.value) {
      queryParams.input_model = this.filterRef.nativeElement.value;
    }

    params.reqTagsMap.forEach((reqTags: RequestTags, category: string) => {
      if (reqTags.names?.length) {
        queryParams[category] = reqTags.names.join(',');
      }
    });

    if (params.docTypes.length) {
      queryParams.doctype = params.docTypes.join(',');
    }

    this.router.navigate(['manual/search'], {
      queryParams,
    });
  }

  /**
   * 自転車のタイプの選択
   * @param index
   */
  onClick(index: any): void {
    this.selectBikeTypeIndex = index;
    this.resultItemService.setFilter(this.bikeTypes[index]);
  }

  /**
   * 通知バーのクローズイベントを受け取る
   */
  closeNotify(): void {
    this.notify = this.notifyService.setNotifyDisable();
    this.searchOffset -= this.NOTIFYBAR_HEIGHT;
  }

  /**
   * 検索バーまでスクロール移動する
   */
  onClickBackToTop(): void {
    const viewportWidth = this.width;
    let offset = 0;

    if (viewportWidth > 760) {
      if (!this.notify.show) {
        offset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset -
          92;
      } else {
        offset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset -
          140;
      }
    } else {
      if (!this.notify.show) {
        offset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset -
          20;
      } else {
        offset =
          this.goRef.nativeElement.getBoundingClientRect().top +
          window.pageYOffset -
          68;
      }
    }

    window.scrollTo({
      top: offset,
      left: 0,
      behavior: 'smooth',
    });
  }

  public get width() {
    return window.innerWidth;
  }
}
