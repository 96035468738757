import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MenuNode } from 'shared';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public node1: Array<MenuNode> = [
    {
      label: $localize`Search`,
      url: 'manual/search',
      target: '_self',
    },
    {
      label: $localize`Update Information`,
      url: 'manual/update-info',
      target: '_self',
    },
    {
      label: $localize`Quick Guide`,
      url: 'information/other/quick-guide',
      target: '_self',
    },
  ];

  public node2: Array<MenuNode> = [
    {
      label: $localize`All Technical Documents`,
      url: 'https://productinfo.shimano.com',
      target: '_blank',
    },
    {
      label: $localize`Line-up chart`,
      url: 'https://productinfo.shimano.com/lineup',
      target: '_blank',
    },
    {
      label: $localize`Specifications`,
      url: 'https://productinfo.shimano.com/spec',
      target: '_blank',
    },
    {
      label: $localize`Compatibility`,
      url: 'https://productinfo.shimano.com/compatibility',
      target: '_blank',
    },
    {
      label: $localize`Archive`,
      url: 'https://productinfo.shimano.com/archive',
      target: '_blank',
    },
  ];

  public node3: Array<MenuNode> = [
    {
      label: $localize`All FAQ`,
      url: `${environment.supportUrl}/faq/`,
      target: '_self',
    },
    {
      label: 'Di2',
      url: `${environment.supportUrl}/faq/DI20A`,
      target: '_self',
    },
    {
      label: 'E-TUBE PROJECT Cyclist',
      url: `${environment.supportUrl}/faq/EPC0A`,
      target: '_self',
    },
    {
      label: 'E-TUBE PROJECT Professional',
      url: `${environment.supportUrl}/faq/EPP0A`,
      target: '_self',
    },
    {
      label: 'E-TUBE RIDE',
      url: `${environment.supportUrl}/faq/ERI0A`,
      target: '_self',
    },
    {
      label: $localize`Power Meter`,
      url: `${environment.supportUrl}/faq/FOV0A`,
      target: '_self',
    },
    {
      label: 'SHIMANO CONNECT Lab',
      url: `${environment.supportUrl}/faq/SCL0A`,
      target: '_self',
      locale: [
        'bg',
        'cs',
        'da',
        'de',
        'el',
        'en',
        'es',
        'et',
        'fi',
        'fr',
        'hr',
        'hu',
        'id',
        'it',
        'ja',
        'ko',
        'lt',
        'lv',
        'nl',
        'no',
        'pl',
        'pt',
        'ro',
        'ru',
        'sk',
        'sl',
        'sr',
        'sv',
        'th',
        'tr',
        'zh-TW',
      ],
    },
    {
      label: 'SHIMANO E-BIKE SYSTEMS',
      url: `${environment.supportUrl}/faq/STP0A`,
      target: '_self',
    },
  ];

  public node4: Array<MenuNode> = [
    {
      label: $localize`All Compliance Documents`,
      url: 'compliance',
      target: '_self',
    },
    {
      label: $localize`Regulatory Information`,
      url: 'compliance/ri',
      target: '_self',
    },
    {
      label: $localize`Declaration of Conformity`,
      url: 'compliance/doc',
      target: '_self',
    },
    {
      label: $localize`Safety Data Sheet`,
      url: 'compliance/sds',
      target: '_self',
    },
    {
      label: $localize`Open Source Software`,
      url: 'compliance/oss',
      target: '_self',
    },
    {
      label: 'RoHS',
      url: 'compliance/rohs',
      target: '_self',
      locale: ['zh-CN'],
    },
    {
      label: $localize`Package Information`,
      url: 'compliance-search/pmi',
      target: '_self',
      locale: ['it'],
    },
    {
      label: $localize`Package Information`,
      url: 'compliance/pmi',
      target: '_self',
      locale: ['fr'],
    },
  ];

  public menuItems: Array<MenuNode> = [
    {
      label: $localize`Top`,
      url: '',
      children: this.node1,
      target: '_self',
    },
    {
      label: $localize`Technical Documents`,
      url: 'https://productinfo.shimano.com',
      children: this.node2,
      target: '_blank',
    },
    {
      label: $localize`Faq`,
      url: `${environment.supportUrl}/faq/`,
      children: this.node3,
      target: '_self',
    },
    {
      label: $localize`Error & Warning`,
      url: 'error',
      target: '_self',
    },
    {
      label: $localize`Compliance`,
      url: 'compliance',
      children: this.node4,
      target: '_self',
    },
    {
      label: 'CUES',
      url: 'cues',
      target: '_self',
    },
    {
      label: $localize`Crank Inspection`,
      url: 'https://si.shimano.com/dm/RAFC012',
      target: '_self',
    },
  ];

  public publicLocales = [
    { locale: 'en', name: 'English' },
    { locale: 'ja', name: '日本語' },
    { locale: 'zh-CN', name: '简体中文' },
    { locale: 'zh-TW', name: '繁體中文' },
    { locale: 'fr', name: 'français' },
    { locale: 'es', name: 'español' },
    { locale: 'de', name: 'Deutsch' },
    { locale: 'it', name: 'Italiano' },
    { locale: 'cs', name: 'Česky' },
    { locale: 'sk', name: 'Slovensky' },
    { locale: 'hu', name: 'Magyar' },
    { locale: 'ru', name: 'Русский' },
    { locale: 'nl', name: 'Nederlands' },
    { locale: 'sv', name: 'svenska' },
    { locale: 'fi', name: 'suomi' },
    { locale: 'no', name: 'Norsk' },
    { locale: 'pl', name: 'Polski' },
    { locale: 'da', name: 'dansk' },
    { locale: 'lt', name: 'Lietuvių kalba' },
    { locale: 'lv', name: 'Latviski' },
    { locale: 'et', name: 'Eesti' },
    { locale: 'el', name: 'Ελληνικά' },
    { locale: 'sl', name: 'Slovenščina' },
    { locale: 'ro', name: 'Română' },
    { locale: 'bg', name: 'Български' },
    { locale: 'tr', name: 'Türkçe' },
    { locale: 'sr', name: 'Српски' },
    { locale: 'hr', name: 'Hrvatski' },
    { locale: 'pt', name: 'Português' },
    { locale: 'ko', name: '한국어' },
    { locale: 'th', name: 'ไทย' },
    { locale: 'id', name: 'Bahasa Indonesia' },
  ];

  public message = '';

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  getNav(): Array<MenuNode> {
    this.menuItems.map((menuItem) => {
      if (menuItem.children !== undefined) {
        menuItem.children = menuItem.children.filter((item) => {
          if (item.locale !== undefined) {
            if (item.locale.indexOf(this.locale) !== -1) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
      }
    });

    return this.menuItems;
  }

  getPublicLocales(): Array<any> {
    return this.publicLocales;
  }
}
